import {useFormContext, useWatch} from 'react-hook-form';
import {Stack, Heading} from '@halp/ui';
import type {StudentDocumentType} from '@halp/util/constants';
import {
	ENGLISH_PROFICIENCY_TEST,
	REQUIRED_DOCUMENTS,
} from '@halp/util/constants';
import {useI18n} from '../i18n';
import {FormSelect} from '../Form';
import {SelectedDocumentType} from './SelectedDocumentType';
import type {SelectOption} from '../Form';

interface Props {
	name: string;
}

export interface RequiredApplicationDocument {
	id?: string;
	type: StudentDocumentType;
}

export function DocumentTypeSelector({name}: Props) {
	const {t} = useI18n();
	const {setValue} = useFormContext();
	const currentDocuments = useWatch({
		name: `${name}`,
	}) as RequiredApplicationDocument[];

	function handleRequiredDoc(option: SelectOption) {
		setValue(name, [...currentDocuments, {type: option.value}]);
	}

	function removeRequiredDoc(documentType: string) {
		const newDocuments = currentDocuments.filter(
			(doc) => doc.type !== documentType,
		);
		setValue(name, newDocuments);
	}

	const englishTestTypes = Object.keys(ENGLISH_PROFICIENCY_TEST);

	const requiredDocOptions = Object.entries(REQUIRED_DOCUMENTS)
		.filter(([key, _value]) => {
			const inCurrentEntries = !!currentDocuments.find(
				(doc) => doc.type === key,
			);
			const inExcludedDocOptions = ['OTHER', ...englishTestTypes].includes(key);
			return !inExcludedDocOptions && !inCurrentEntries;
		})
		.map(([key, value]) => ({
			label: value,
			value: key,
		}));

	return (
		<Stack direction="column" alignItems="stretch">
			<Heading type="h5" spacing="md">
				{t('program_recommendation.application_requirements.title')}
			</Heading>
			<FormSelect
				name="docs"
				options={requiredDocOptions}
				onChange={(option) => handleRequiredDoc(option as SelectOption)}
			/>
			<SelectedDocumentType
				documents={currentDocuments}
				onDelete={removeRequiredDoc}
			/>
		</Stack>
	);
}
