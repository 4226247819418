import type {IntegerRange} from '@halp/ui/';
import {SkeletonText, Grid} from '@halp/ui';
import type {SelectOptions} from '@halp/ui/Select';
import type {LevelOfStudy} from '@halp/util';
import {
	BOOLEAN_STRING,
	formatIntakePeriod,
	intakePeriodToDate,
	STUDY_LEVELS,
} from '@halp/util';
import {useQueryTable} from '../../DataTable';
import {useI18n} from '../../i18n';
import {useSelectedUser} from '../../Students/SelectedStudentProvider';
import style from './ProgramFilters.module.css';
import {useProgramFilters} from './useProgramFilters';
import type {ProgramsTableFiltersType} from '../ProgramsTable/types';
import type {FloatRange} from '../ProgramSelectors';
import type {ProgramLengthRange} from '../types';

interface Option {
	label: string;
	value: string;
}

export interface Props {
	filters?: ProgramsTableFiltersType[] | null;
	levelOfStudy?: LevelOfStudy;
	setLevelOfStudy?: (id: LevelOfStudy | undefined) => void;
	schoolId?: string;
	setSchool?: (id: string | undefined) => void;
	subjectId?: string;
	subjectName?: string;
	setSubject?: (id: string | undefined) => void;
	majorId?: string;
	majorName?: string;
	setMajor?: (id: string | undefined) => void;
	country?: string;
	setCountry?: (id: string | undefined) => void;
	province?: string | null;
	setProvince?: (id?: string | null) => void;
	intakePeriod?: string;
	setIntakePeriod?: (id: string | undefined) => void;
	tuitionRange: IntegerRange | undefined;
	programLengthRange: ProgramLengthRange | undefined;
	setProgramLengthRange?: (id: ProgramLengthRange) => void;
	setTuitionRange?: (id: IntegerRange) => void;
	acceptedEpTest?: string;
	setAcceptedEpTest?: (id: string | undefined) => void;
	requiredGradeRange?: FloatRange;
	setRequiredGradeRange?: (id: FloatRange) => void;
	submissionDirect?: boolean | null;
	shouldPushQueryParams?: boolean;
}

export function ProgramFilters({
	filters,
	schoolId,
	subjectId,
	subjectName,
	majorId,
	majorName,
	intakePeriod,
	...props
}: Props) {
	const {t} = useI18n();
	const {user} = useSelectedUser();
	const {data, isLoading} = useQueryTable();

	const intakePeriodOptions: SelectOptions<Option> = (
		data?.[0].programsCollection?.intakePeriods as {
			startDate: string;
			intakePeriod: string;
		}[]
	)
		?.map((intake) => ({
			label: formatIntakePeriod(intake.intakePeriod),
			value: intake.intakePeriod,
		}))
		.sort((a, b) => {
			return (
				intakePeriodToDate(a.value).getTime() -
				intakePeriodToDate(b.value).getTime()
			);
		});

	const countryOptions: SelectOptions<Option> = (
		data?.[0].programsCollection?.countries as string[]
	)?.map((country) => ({
		label: country,
		value: country,
	}));

	const provinceOptions: SelectOptions<Option> = (
		data?.[0].programsCollection?.provinces as string[]
	)?.map((province) => ({
		label: province,
		value: province,
	}));

	const schoolOptions: SelectOptions<Option> =
		data?.[0].programsCollection?.schools?.map(
			(school: {id: string; name: string}) => ({
				label: school.name,
				value: school.id,
			}),
		);

	const majorOptions: SelectOptions<Option> =
		data?.[0].programsCollection?.majors?.map(
			(major: {id: string; name: string}) => ({
				label: major.name,
				value: major.id,
			}),
		);

	const subjectOptions: SelectOptions<Option> =
		data?.[0].programsCollection?.subjects?.map(
			(subject: {id: string; name: string}) => ({
				label: subject.name,
				value: subject.id,
			}),
		);

	const studyLevelOptions = (Object.keys(STUDY_LEVELS) as LevelOfStudy[]).map(
		(level) => ({
			label: t(STUDY_LEVELS[level]),
			value: level,
		}),
	);

	const acceptedEpTestOptions = [
		{label: t('english_test.ielts'), value: 'IELTS'},
		{label: t('english_test.toefl'), value: 'TOEFL'},
		{label: t('english_test.pte'), value: 'PTE'},
		{label: t('english_test.duolingo'), value: 'DUOLINGO'},
	];

	const selectedSchool = data?.[0].programsCollection?.schools?.find(
		(school: {id: string; name: string}) => school.id === schoolId,
	);

	const selectedMajor = data?.[0].programsCollection?.majors?.find(
		(major: {id: string; name: string}) => major.id === majorId,
	) ?? {id: 0, name: majorName};

	const selectedSubject = data?.[0].programsCollection?.subjects?.find(
		(subject: {id: string; name: string}) => subject.id === subjectId,
	) ?? {id: 0, name: subjectName};

	const selectedIntakePeriod = intakePeriod
		? data?.[0].programsCollection?.intakePeriods?.find(
				(intake: {startDate: string; intakePeriod: string}) =>
					intake.intakePeriod === intakePeriod,
			)
		: intakePeriodOptions?.[-1];

	const submissionDirectOptions = [
		{label: t(BOOLEAN_STRING['true']), value: true},
		{label: t(BOOLEAN_STRING['false']), value: false},
	];

	const filterMap = useProgramFilters({
		studyLevelOptions,
		selectedSchool,
		schoolOptions,
		selectedSubject,
		subjectOptions,
		selectedMajor,
		majorOptions,
		countryOptions,
		provinceOptions,
		intakePeriodOptions,
		selectedIntakePeriod,
		acceptedEpTestOptions,
		studentGrade: user?.studentProfile?.grades,
		submissionDirectOptions,
		...props,
	});

	if (isLoading) {
		return <SkeletonText count={1} />;
	}

	return (
		<Grid columns={4} gridGap="sm" className={style.Filters}>
			{filters
				? filters.map((filter) => filterMap[filter])
				: Object.values(filterMap).map((filter) => filter)}
		</Grid>
	);
}
