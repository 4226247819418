import {useRef, useState} from 'react';
import {
	FloatingArrow,
	arrow,
	flip,
	offset,
	shift,
	useFloating,
	useClick,
	useInteractions,
	useDismiss,
} from '@floating-ui/react';
import classNames from 'classnames';
import style from './Popover.module.css';
import type {ReactNode} from 'react';

export interface Props {
	trigger: ReactNode;
	children: ReactNode;
	disabled?: boolean;
	className?: string;
	open?: boolean;
}

export function Popover({
	trigger,
	className,
	disabled = false,
	children,
	open = false,
}: Props) {
	const [isOpen, setIsOpen] = useState(open);

	const arrowRef = useRef(null);
	const {refs, floatingStyles, context} = useFloating({
		placement: 'top',
		open: isOpen,
		onOpenChange: setIsOpen,
		middleware: [
			offset(5),
			flip({
				fallbackAxisSideDirection: 'start',
			}),
			shift(),
			arrow({
				element: arrowRef,
			}),
		],
	});
	const click = useClick(context, {enabled: !disabled});
	const dismiss = useDismiss(context);

	const {getReferenceProps, getFloatingProps} = useInteractions([
		click,
		dismiss,
	]);

	return (
		<>
			<div
				className={classNames(style.Trigger, className)}
				ref={refs.setReference}
				{...getReferenceProps()}
			>
				{trigger}
			</div>
			{isOpen ? (
				<div
					ref={refs.setFloating}
					style={floatingStyles}
					{...getFloatingProps()}
					className={style.Popover}
				>
					{children}
					<FloatingArrow
						ref={arrowRef}
						context={context}
						fill="var(--base-color-dark)"
					/>
				</div>
			) : null}
		</>
	);
}
