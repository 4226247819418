import {
	faChalkboardTeacher,
	faUserGraduate,
	faIdCard,
	faBook,
	faCircle,
	faClipboardQuestion,
} from '@fortawesome/free-solid-svg-icons';
import {Paragraph, SidebarNav} from '@halp/ui';
import {Paths} from '@halp/foundation/Paths';
import {AdminUserMenu, useUser} from '@halp/foundation/Users';
import {CoachStudentsByFunnelStageDocument} from '@halp/foundation/Students/StudentsTable/CoachStudentsByFunnelStage.query';
import {useQuery} from '@halp/api/graphql';
import type {IconSize} from '@halp/ui/SidebarNav/components/NavLink/NavLink';
import {imgLogoWhite} from '@halp/images';
import style from './SideNav.module.css';

function StudentFunnelStageCount({count}: {count?: number}) {
	return (
		<Paragraph type="caption" className={style.StudentFunnelStageCount}>
			{count ?? 0}
		</Paragraph>
	);
}

export function SideNav({
	setOpenMenu,
}: {
	mobile?: boolean;
	setOpenMenu?: (open: boolean) => void;
}) {
	const {user} = useUser();

	const coachId = user?.coachProfile?.id || '';
	const {data} = useQuery(CoachStudentsByFunnelStageDocument, {
		variables: {coachId: coachId},
	});
	const studentsByFunnelStage = data?.coach?.studentsByFunnelStage;

	const items = [
		{type: 'heading', text: 'Dashboard'} as const,
		{
			text: 'My Students',
			path: Paths.coach.index.url(),
			icon: faChalkboardTeacher,
			expandable: true,
			subItems: [
				{
					text: 'Get Started',
					textDecoration: (
						<StudentFunnelStageCount
							count={studentsByFunnelStage?.getStarted}
						/>
					),
					iconSize: 'sm' as IconSize,
					icon: faCircle,
					iconColor: 'var(--color-violet-6)',
					path: Paths.users.coaching.getStarted.url(coachId, 'coach'),
				},
				{
					text: 'Discover',
					textDecoration: (
						<StudentFunnelStageCount count={studentsByFunnelStage?.discover} />
					),
					iconSize: 'sm' as IconSize,
					icon: faCircle,
					iconColor: 'var(--color-blue-7)',
					path: Paths.users.coaching.discover.url(coachId, 'coach'),
				},
				{
					text: 'Prep',
					textDecoration: (
						<StudentFunnelStageCount count={studentsByFunnelStage?.prep} />
					),
					iconSize: 'sm' as IconSize,
					icon: faCircle,
					iconColor: 'var(--color-green-7)',
					path: Paths.users.coaching.prep.url(coachId, 'coach'),
				},
				{
					text: 'Submit',
					textDecoration: (
						<StudentFunnelStageCount count={studentsByFunnelStage?.submit} />
					),
					iconSize: 'sm' as IconSize,
					icon: faCircle,
					iconColor: 'var(--color-yellow-7)',
					path: Paths.users.coaching.apply.url(coachId, 'coach'),
				},
				{
					text: 'Accepted',
					textDecoration: (
						<StudentFunnelStageCount count={studentsByFunnelStage?.accepted} />
					),
					iconSize: 'sm' as IconSize,
					icon: faCircle,
					iconColor: 'var(--color-orange-5)',
					path: Paths.users.coaching.accepted.url(coachId, 'coach'),
				},
				{
					text: 'Visa',
					textDecoration: (
						<StudentFunnelStageCount count={studentsByFunnelStage?.visa} />
					),
					iconSize: 'sm' as IconSize,
					icon: faCircle,
					iconColor: 'var(--color-pink-4)',
					path: Paths.users.coaching.visa.url(coachId, 'coach'),
				},
				{
					text: 'Arrive',
					textDecoration: (
						<StudentFunnelStageCount count={studentsByFunnelStage?.setUp} />
					),
					iconSize: 'sm' as IconSize,
					icon: faCircle,
					iconColor: 'var(--color-red-7)',
					path: Paths.users.coaching.arrive.url(coachId, 'coach'),
				},
				{
					text: 'Placed',
					textDecoration: (
						<StudentFunnelStageCount count={studentsByFunnelStage?.placed} />
					),
					iconSize: 'sm' as IconSize,
					icon: faCircle,
					iconColor: 'var(--color-purple-7)',
					path: Paths.users.coaching.placed.url(coachId, 'coach'),
				},
			],
		},
		{type: 'heading', text: 'Users'} as const,
		{
			text: 'Students',
			path: Paths.users.students.verified.url('coach'),
			icon: faUserGraduate,
		},
		{
			text: 'Coaches',
			path: Paths.coach.coaches.index.url(),
			icon: faIdCard,
		},
		{type: 'heading', text: 'Resources'} as const,
		{
			text: 'Handbook',
			path: Paths.coach.handbook,
			icon: faClipboardQuestion,
			target: '_blank',
		},
		{
			text: 'Programs',
			path: Paths.programs.programs.index.url('coach'),
			icon: faBook,
		},
		{type: 'break'} as const,
	].flatMap((tab) => (tab ? [tab] : []));

	return (
		<SidebarNav logo={imgLogoWhite} items={items} toggle={setOpenMenu}>
			<AdminUserMenu />
		</SidebarNav>
	);
}
