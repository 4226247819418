import dynamic from 'next/dynamic';
export {type Options} from 'react-select';
import classNames from 'classnames';
import style from './Select.module.css';
import type StateManagedSelect from 'react-select';
import type {GroupBase} from 'react-select';
import type {StateManagerProps} from 'react-select/dist/declarations/src/useStateManager';

const ReactSelect = dynamic(() => import('react-select'), {
	ssr: false,
	loading: () => <input />,
}) as StateManagedSelect;

export const AsyncSelect = dynamic(() => import('react-select/async'), {
	ssr: false,
	loading: () => <input />,
});

export function Select<
	Option,
	IsMulti extends boolean = false,
	Group extends GroupBase<Option> = GroupBase<Option>,
>(props: StateManagerProps<Option, IsMulti, Group>) {
	const selectClassName = classNames(props.className, style.Select);
	return (
		<ReactSelect
			{...props}
			className={selectClassName}
			classNamePrefix="form-select"
		/>
	);
}
